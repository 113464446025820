exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-experiencia-js": () => import("./../../../src/pages/es/experiencia.js" /* webpackChunkName: "component---src-pages-es-experiencia-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-manifiesto-js": () => import("./../../../src/pages/es/manifiesto.js" /* webpackChunkName: "component---src-pages-es-manifiesto-js" */),
  "component---src-pages-es-servicios-js": () => import("./../../../src/pages/es/servicios.js" /* webpackChunkName: "component---src-pages-es-servicios-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifest-js": () => import("./../../../src/pages/manifest.js" /* webpackChunkName: "component---src-pages-manifest-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

